import VueRouter from "vue-router";
import store from "./store";
import Login from "./pages/Login";
import Dashboard from "./pages/account/Dashboard";
import WebApp from "./pages/account/WebApp";
import Project from "./pages/account/Project";
import Permissions from "./pages/account/Permissions";
import BookingHistory from "./pages/account/BookingHistory";
import UserList from "./pages/account/UserList";
import Report from "./pages/account/Report";
import ReportV3 from "./pages/account/ReportV3";
import ChapterImages from "./pages/account/ChapterImages";
import ReportSettings from "./pages/account/ReportSettings";
import TableTest from "./pages/TableTest";
import Contacts from "./pages/account/Contacts";
import Sites from "./pages/account/Sites";
import SiteDetail from "./pages/account/SiteDetail";

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/web-app",
    name: "web-app",
    component: WebApp,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project-detail/:uid?",
    name: "project",
    component: Project,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: Permissions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking-history",
    name: "bookinghistory",
    component: BookingHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-list",
    name: "userlist",
    component: UserList,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report/:projectUid?/:uid?",
    name: "report",
    component: Report,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-v3/:lang/:project_id/:inspect_id/:uid?",
    name: "reportV3",
    component: ReportV3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/image-library",
    name: "imagelib",
    component: ChapterImages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report-settings",
    name: "reportsettings",
    component: ReportSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(to, from, next) {
      store.commit("logout");
      next("/login");
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      nocheck: true,
    },
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sites",
    name: "sites",
    component: Sites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/site/:uid",
    name: "site-detail",
    component: SiteDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/table-test",
    name: "table-test",
    component: TableTest,
    meta: {
      nocheck: true,
    },
  },
  {
    path: "",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  //next()
  //return
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //console.log("store.getters.authStatus",  store.getters.authStatus)
    //console.log(store.state.token)
    if (store.getters.authStatus) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
