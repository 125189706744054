<template>
  <div>
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap">
          <Header v-show="!loading_page" />
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container wide-xl">
              <div class="nk-content-inner">
                <Navigation v-show="!loading_page" />
                <!--
                <div class="nk-content-body" v-show="loading_page">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                -->

                <div class="nk-content-body" v-show="!loading_page">
                  <div class="nk-content-wrap">
                    <div class="nk-block-head nk-block-head-lg">
                      <div class="nk-block-between-md g-4">
                        <div class="nk-block-head-content">
                          <h2 class="nk-block-title fw-normal">
                            Welcome {{ loginName }}
                          </h2>
                          <p>
                            {{ companyName }}
                          </p>
                          <!--
                          <div class="nk-block-des">
                            <p>Welcome to your 8.2 Inspect dashboard.</p>
                          </div>
                          -->
                        </div>
                      </div>
                    </div>
                    <!-- .nk-block-head -->

                    <div class="nk-block nk-block-lg">
                      <div class="nk-block-head">
                        <div class="nk-block-head-content">
                          <h4 class="nk-block-title">Projects</h4>
                          <p>
                            The following
                            <span v-if="projects">{{ projects.length }}</span>
                            projects are available in Inspect.
                          </p>
                        </div>
                      </div>

                      <div class="btn btn-secondary btn-dim btn-sm" @click="addProject()" v-if="canAddProject"
                        style="margin: 10px 0 20px 0">
                        <span>Add project</span>
                      </div>

                      <div class="form-control-wrap" style="margin-bottom: 20px">
                        <div class="input-group">
                          <input v-model="searchkey" type="text" class="form-control" placeholder="Filter" />
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col" style="cursor: pointer" @click="sortCol('projectnumber')">
                                Project
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('projectname')">
                                Name
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('customer')" class="hide-mobile">
                                Customer
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('external')" class="hide-mobile">
                                External
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in projects" :key="item.uid" class="table-row" @click="selectProject(item)">
                              <td>{{ item.projectnumber }}</td>
                              <td>{{ item.projectname }}</td>
                              <td class="hide-mobile">{{ item.customer }}</td>
                              <td>
                                <span v-if="item.external" class="material-icons md-48 hide-mobile"
                                  style="font-size: 18px;margin-right:5px;">cloud_done</span>
                                <span v-if="item.collaborator && !item.member" class="material-icons md-48 hide-mobile"
                                  style="font-size: 18px;">supervised_user_circle</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <!--
                      <v-grid
                        style="height: 500px"
                        theme="compact"
                        resize="true"
                        auto-size-column="true"
                        :source="projects"
                        :columns="columns"
                        readonly="true"
                        canFocus="false"
                        v-if="projects && projects.length > 0"
                      ></v-grid>
-->
                    </div>

                    <div class="example-alert" v-if="hasExternalProjects">
                      <div class="alert alert-light alert-icon">
                        <em class="icon ni ni-alert-circle"></em> The
                        <strong>external</strong> projects have been assigned to
                        you in PDB or Vertec and can only be managed there.
                      </div>
                    </div>

                    <!-- .nk-block -->
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->

      <!-- Modal Content Code -->
      <div class="modal fade" tabindex="-1" id="modalDefault">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <a href="#" class="close" data-dismiss="modal" aria-label="Close">
              <em class="icon ni ni-cross"></em>
            </a>
            <div class="modal-header">
              <h5 class="modal-title">Add new project</h5>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger" v-if="errorMessage">
                {{ errorMessage }}
              </div>
              <form action="#" class="form-validate is-alter" novalidate="novalidate">
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Project number</label>
                        <div class="form-control-wrap">
                          <input placeholder="min. 5 characters" type="text" class="form-control"
                            v-model="projectEntry.projectNumber" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Name</label>
                        <div class="form-control-wrap">
                          <input placeholder="min. 5 characters" type="text" class="form-control"
                            v-model="projectEntry.name" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button v-if="!projectLoading" type="button" class="btn btn-lg btn-primary"
                    @click="createProject(projectEntry)">
                    Save project
                  </button>
                </div>
              </form>
            </div>
            <div class="modal-footer bg-light"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";
import { accountSetup } from "@/services/login.service";

//import { EventBus } from '@/event-bus.js';
import { createProject } from "@/services/project.service";

export default {
  name: "Home",
  data() {
    return {
      fullLoader: null,
      projectEntry: {},
      mapSort: {},
      searchkey: "",
      db_result: [],
      errorMessage: '',
      projectLoading: false,
      columns: [
        { prop: "projectnumber", name: "Project", sortable: true, size: 100 },
        { prop: "projectname", name: "Name", sortable: true, size: 200 },
        { prop: "customer", name: "Customer", sortable: true, size: 200 },
        // { prop: "external", name: "External", sortable: true  },
      ],
      rows: [],
    };
  },
  components: {
    Header,
    Navigation,
    Footer,
    //VGrid
  },

  computed: {
    hasExternalProjects: function () {
      if (this.db_result) {
        for (let k = 0; k < this.db_result.length; k++) {
          if (this.db_result[k].external) {
            return true;
          }
        }
      }
      return false;
    },

    canAddProject: function () {
      return this.$store.state.login_system == "inspect";
    },
    companyName: function () {
      return this.$store.state.company;
    },
    loginName: function () {
      return this.$store.state.name;
    },
    projects_db: function () {
      return this.$store.state.projects;
    },
    loading_page: function () {
      return (
        this.$store.state.loading_page &&
        this.$store.state.loading_page == "setup"
      );
    },
    projects() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.db_result.filter((project) => {
          return (
            (project.projectname &&
              project.projectname
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1) ||
            (project.projectnumber &&
              project.projectnumber
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1) ||
            (project.customer &&
              project.customer
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1)
          );
        });
      }

      return this.db_result;
    },
  },
  mounted() {
    this.db_result = this.projects_db;
    window.scrollTo(0, 0);
  },
  created() {
    //EventBus.$off('project:table:select');
    //EventBus.$on('project:table:select', this.selectProject);

    //this.$store.state.account_setup = false;
    if (!this.$store.state.account_setup) {
      this.$store.state.account_setup = true;

      this.$store.commit("set_loading_page", "setup");
      this.showLoader();
      accountSetup()
        .then((d) => {
          this.hideLoder();
          if (d.data.name) {
            this.$store.state.name = d.data.name;
          }
          if (d.data.projects) {

            let newTestProject = JSON.parse(JSON.stringify(this.$store.state.testProject))

            console.log("hallo", d.data, this.$store.state.userId)

            if (d.data.userId && !d.data.old_testproject) {
              newTestProject.projectId = "00000000" + d.data.userId
              newTestProject._id = "00000000" + d.data.userId
              newTestProject.uid = "00000000" + d.data.userId
              newTestProject.projectnumber = "00000000" + d.data.userId
            }


            this.$store.commit("set_projects", d.data.projects);
          } else {
            this.$store.commit("set_projects", [this.$store.state.testProject]);
          }

          if (d.data.account_role) {
            this.$store.state.account_role = d.data.account_role;
          }

          this.$store.commit("set_loading_page", null);
        })
        .catch((e) => {
          this.$store.dispatch("api_error", e);
        });
    }
  },
  watch: {
    "$store.state.projects": function () {
      this.db_result = this.$store.state.projects;
    },
  },
  methods: {
    selectProject: function (data) {
      this.$router.push({ name: "project", params: { uid: data.uid } });
    },
    hideLoder: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },

    sortCol(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.db_result = this.db_result.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    addProject: function () {
      this.projectEntry = {};
      this.errorMessage = ''
      $("#modalDefault").modal("show");
    },
    createProject: function () {
      this.errorMessage = ''
      if (
        this.projectEntry.name &&
        this.projectEntry.name.length > 4 &&
        this.projectEntry.projectNumber &&
        this.projectEntry.projectNumber.length > 4
      ) {
        this.projectLoading = true;
        createProject(
          this.projectEntry.projectNumber,
          this.projectEntry.name
        ).then((data) => {
          this.projectLoading = false;

          if (data.data.error) {
            this.errorMessage = 'Project Number already exists'
          } else {
            $("#modalDefault").modal("hide");
            if (data.data.list) {
              this.$store.commit("set_projects", data.data.list);
            }

            if (data.data.project) {
              this.$router.push({
                name: "project",
                params: { uid: data.data.project.uid },
              });
            }
          }
        });
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style scoped>
.revo-grid {
  height: 50%;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f5f5f5;
}
</style>
