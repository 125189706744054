import * as axios from "axios";

function getMembers() {
  const data = {
    project_username: "",
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/permission-members`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function addMember(username) {
  const data = {
    permission_username: username,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/permission-member-add`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function updateMember(username, cat, val) {
  const data = {
    permission_username: username,
    permission_cat: cat,
    permission_val: val,
  };

  console.log("data", data);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/permission-member-update`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function removeMember(username) {
  const data = {
    permission_username: username,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/permission-member-remove`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function updateSettings(type, value) {
  const data = {
    pm_value: value,
    pm_type: type,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/permission-settings`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function getSettings(type) {
  const data = {
    pm_type: type,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/permission-get-settings`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function getBookings(date, export_csv, all = false) {
  const data = {
    date: date,
    export_csv: export_csv,
    all: all,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/get-bookings`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function getUsers(export_csv = false) {
  const data = {
    export_csv: export_csv,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/get-users`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function updateUser(userData) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const data = {
    userData: userData,
  };

  const url = `/inspect-backend/user-update`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

export {
  addMember,
  updateMember,
  removeMember,
  getMembers,
  updateSettings,
  getSettings,
  getBookings,
  getUsers,
  updateUser,
};
