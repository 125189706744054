import * as axios from "axios";

const baseUrlPath = "/api/8p2reporting-v3";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
};

function getInspection(project_id, inspect_id, lang, version, project_uid) {
  const data = {
    project_id: project_id,
    inspect_id: inspect_id,
    lang: lang,
    version: version,
    project_uid: project_uid,
  };

  const url = baseUrlPath + `/get-inspection`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function getInspections(project_id, inspect_id) {
  const data = {
    project_id: project_id,
    inspect_id: inspect_id,
  };

  const url = baseUrlPath + `/inspections`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function getChapterSettings(projectNumber, inspection, chapterId, projectUid) {
  const data = {
    projectNumber: projectNumber,
    inspection: inspection,
    chapterId: chapterId,
    projectUid: projectUid,
  };

  const url = baseUrlPath + `/get-chapter-settings`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function saveChapterSettings(
  projectNumber,
  inspection,
  chapterId,
  chapterSettings
) {
  const data = {
    projectNumber: projectNumber,
    inspection: inspection,
    chapterId: chapterId,
    chapterSettings: chapterSettings,
  };

  const url = baseUrlPath + `/save-chapter-settings`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function updateChapterMarker(
  projectNumber,
  inspection,
  chapterId,
  results,
  resultImage
) {
  const data = {
    projectNumber: projectNumber,
    inspection: inspection,
    chapterId: chapterId,
    results: results,
    resultImage: resultImage,
  };

  const url = baseUrlPath + `/update-chapter-marker`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function getImgLib() {
  const data = {};

  const url = baseUrlPath + `/get-imagelib`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function getProjectImgLib(project_uid) {
  const data = {
    project_uid: project_uid,
  };

  const url = baseUrlPath + `/get-projectimagelib`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function renameImgLib(uid, name) {
  const data = {
    file_uid: uid,
    file_name: name,
  };

  const url = baseUrlPath + `/rename-imagelib`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function removeImgLib(uid, file) {
  const data = {
    file_uid: uid,
    file_file: file,
  };

  const url = baseUrlPath + `/remove-imagelib`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function removeProjectImgLib(uid, file, project_uid) {
  const data = {
    file_uid: uid,
    file_file: file,
    project_uid: project_uid,
  };

  const url = baseUrlPath + `/remove-projectimagelib`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function getReportSettings(projectNumber, inspection, projectUid) {
  const data = {
    projectNumber: projectNumber,
    inspection: inspection,
    projectUid: projectUid,
  };

  const url = baseUrlPath + `/get-report-settings`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function saveReportSettings(projectNumber, inspection, reportData) {
  const data = {
    projectNumber: projectNumber,
    inspection: inspection,
    reportData: reportData,
  };

  const url = baseUrlPath + `/save-report-settings`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function generateXLSX(
  project_id,
  inspect_id,
  lang,
  report_name,
  lastVersion,
  project_uid
) {
  const data = {
    project_id: project_id,
    inspect_id: inspect_id,
    lang: lang,
    report_name: report_name,
    lastVersion: lastVersion,
    project_uid: project_uid,
  };

  const url = baseUrlPath + `/generate-xlsx`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function generateReport(
  project_id,
  inspect_id,
  lang,
  report_name,
  lastVersion,
  project_uid
) {
  const data = {
    project_id: project_id,
    inspect_id: inspect_id,
    lang: lang,
    report_name: report_name,
    lastVersion: lastVersion,
    project_uid: project_uid,
  };

  const url = baseUrlPath + `/generate-pdf`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function getMainReportSettings() {
  const data = {};

  const url = baseUrlPath + `/get-mainreport-settings`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

function saveMainReportSettings(settings) {
  const data = {
    settings: settings,
  };

  const url = baseUrlPath + `/save-mainreport-settings`;
  return axios
    .post(url, data, headers)
    .then((x) => x.data)
    .then((x) => {
      return x;
    });
}

export {
  getInspections,
  getChapterSettings,
  saveChapterSettings,
  getInspection,
  updateChapterMarker,
  getImgLib,
  renameImgLib,
  removeImgLib,
  removeProjectImgLib,
  getProjectImgLib,
  getReportSettings,
  saveReportSettings,
  generateReport,
  getMainReportSettings,
  saveMainReportSettings,
  generateXLSX,
};
