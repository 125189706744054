<template>

    <!-- footer @s -->
    <div class="nk-footer">
        <div class="container wide-xl">
            <div class="nk-footer-wrap g-2">
                <div class="nk-footer-copyright"> &copy; 2025 8.2 Inspect
                </div>

            </div>
        </div>
    </div>
    <!-- footer @e -->

</template>

<script>


export default {
    name: 'Footer',
    components: {
    },
    computed: {

    }
}
</script>

<style></style>
