<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-large">
            <div class="nk-content-inner">
              <div class="nk-content-body">
                <div v-if="!loading && inspection_count == 0">
                  <h3>This project has no content yet.</h3>
                </div>




                <div class="card card-preview" style="margin-bottom: 20px" v-if="!loading && inspection_count > 0">
                  <div>
                    <div class="preview-block">
                      <div class="row" style="margin: 0">
                        <div class="col-sm-2" style="padding: 0px;">
                          <div class="container-col1">
                            <div class="content-col1">
                              <table class="table">
                                <tbody>
                                  <tr v-for="item in inspections" :key="item.uid" :class="{
                                    insection_selected:
                                      item.uid === inspect_id,
                                  }" class="inspection-row" @click="selectInspectionRow(item)">
                                    <td :style="{
                                      'border-left':
                                        '8px solid ' + item.color,
                                    }">
                                      <b>{{ item.machineName }}</b>
                                      <div>{{ item.project_cat }}</div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div :class="showPreview ? 'col-sm-2' : 'col-sm-4'" style="
                            background-color: #f5f6fa;
                            padding-top: 20px;
                            padding-bottom: 20px;
                          ">
                          <div v-if="inspection_loading">
                            Loading inspection...
                          </div>
                          <div v-if="!inspection_loading">
                            <h4 v-if="inspectionEntry">
                              {{ inspectionEntry.machine.name }}

                              <span v-if="inspectionEntry.machine.siteNumber">({{
                                inspectionEntry.machine.siteNumber
                                }})</span>
                            </h4>


                            <a href="#" @click="reportSettings(inspectionEntry)"
                              class="btn btn-dim btn-outline-dark">Settings</a>
                            <div v-if="versionList && versionList.length > 1" style="margin-top: 10px">
                              <select class="form-control" v-model="lastVersion" @change="changeVersion()">
                                <option v-for="(item, index) in versionList" :key="index" :value="item">
                                  {{ item.type }}{{ item.revision }}
                                </option>
                              </select>
                            </div>

                            <div v-if="inspectionEntry" style="
                                font-size: 12px;
                                padding-top: 12px;
                                margin-top: 10px;
                                border-top: 1px solid #cccccc;
                              ">
                              <span> {{ reportName }}</span>
                              <div v-if="inspection_base">
                                {{ inspection_base.project_cat }}
                                ({{ lastVersion.type
                                }}{{ lastVersion.revision }})
                              </div>
                            </div>

                            <div v-if="inspectionEntry" style="font-size: 12px; margin-top: 10px">
                              <span v-if="inspectionContent &&
                                inspectionContent.title &&
                                inspectionContent.machineManufacturer
                              " class="badge badge-outline-info">{{
                                inspectionContent.title.machineManufacturer
                                  .text
                              }}</span>

                              <div class="chapterTable">
                                <div class="chapter-headline" @click="selectChapter('general')" v-if="inspectionContent &&
                                  inspectionContent.general &&
                                  inspectionContent.general.title
                                ">
                                  <h6>
                                    {{ inspectionContent.general.title.text }}
                                  </h6>
                                </div>

                                <div class="chapter-headline" @click="selectChapter('machinedata')" v-if="inspectionContent &&
                                  inspectionContent.machinedata &&
                                  inspectionContent.machinedata.title
                                ">
                                  <h6>
                                    {{
                                      inspectionContent.machinedata.title.text
                                    }}
                                  </h6>
                                </div>
                                <div class="chapter-headline" @click="selectChapter('components')" v-if="inspectionContent &&
                                  inspectionContent.components &&
                                  inspectionContent.components.title &&
                                  inspectionContent.components.list.length > 0
                                ">
                                  <h6>
                                    {{
                                      inspectionContent.components.title.text
                                    }}
                                  </h6>
                                </div>

                                <div class="chapter-headline">
                                  <h6>
                                    {{
                                      inspectionContent &&
                                      inspectionContent.findings &&
                                      inspectionContent.findings.title &&
                                      inspectionContent.findings.title.text
                                    }}
                                  </h6>
                                </div>

                                <div v-for="item in findingsChapters" :key="item.uid">
                                  <div class="chapterRow">
                                    <div class="chapterRow-col2" @click="selectFindingsChapter(item)">
                                      <span v-if="item.level == 3">&nbsp;&nbsp;</span>
                                      <span v-if="item.level == 4">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      <span v-if="item.level == 5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                      {{ item.title.text }} ({{ item.count }})
                                    </div>
                                    <div class="chapterRow-col1">
                                      <div class="chaper-icon" @click="chapterImage(item)" v-if="item.chapterimage">
                                        <span class="material-icons">image</span>
                                      </div>
                                    </div>
                                    <div class="chapterRow-col3">
                                      <div class="chaper-icon" @click="chapterSettings(item)">
                                        <span class="material-icons">settings</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="chapter-headline" @click="selectChapter('conclusion')" v-if="inspectionContent &&
                                  inspectionContent.conclusion &&
                                  inspectionContent.conclusion.title
                                ">
                                  <h6>
                                    {{
                                      inspectionContent.conclusion.title.text
                                    }}
                                  </h6>
                                </div>
                              </div>

                              <div>

                                <div class="btn-group" aria-label="Basic example" v-if="!pdfLoading &&
                                  findingsChapters &&
                                  findingsChapters.length > 0
                                "> <button type="button" @click="generatePdfReport(true)" class="btn btn-primary">
                                    <span v-if="lang == 'en'">Inspection Report</span>
                                    <span v-if="lang == 'de'">Prüfbericht</span></button> <button type="button"
                                    @click="generatePdfReport(false)" class="btn btn-dim btn-outline-dark"> <span
                                      v-if="lang == 'en'">Preview</span>
                                    <span v-if="lang == 'de'">Vorschau</span></button> </div>

                                <div v-if="pdfLoading" class="spinner-border spinner-grow-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div :class="showPreview ? 'col-sm-5' : 'col-sm-6'" style="padding: 20px">
                          <div v-if="!inspection_loading">
                            <div v-if="contentCategory == 'conclusion'">
                              <h4 style="padding-bottom: 20px">
                                {{ inspectionContent.conclusion.title.text }}
                              </h4>
                              <div>
                                <table class="table table-sm">
                                  <tbody>
                                    <tr v-for="item in inspectionContent
                                      .conclusion.list" :key="item.id">
                                      <td>
                                        <span class="badge badge-primary" v-bind:style="stateColorStyle(item.state)
                                          ">{{ renameState(item.state) }}</span>
                                      </td>
                                      <td>
                                        <div v-if="item.reportText &&
                                          item.reportText.length > 1
                                        ">
                                          <b>{{ item.reportText }}</b><br />
                                        </div>
                                        {{ item.text }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div v-for="item in inspectionContent.conclusion
                                .chapters" :key="item.id">
                                <h6 style="padding-top: 20px">
                                  {{ item.title.chapter }}
                                  {{ item.title.text }}
                                </h6>
                                <p>{{ item.text }}</p>
                              </div>
                            </div>

                            <div v-if="contentCategory == 'general'">
                              <h4 style="padding-bottom: 20px">
                                {{ inspectionContent.general.title.text }}
                              </h4>
                              <div v-for="item in inspectionContent.general.list" :key="item.id">
                                <div v-if="item.type == 'list'" style="padding-bottom: 20px">
                                  <h6>
                                    {{ item.title.chapter }}
                                    {{ item.title.text }}
                                  </h6>
                                  <ol style="
                                      list-style-type: square;
                                      padding-left: 20px;
                                    ">
                                    <li v-for="item2 in item.list" :key="item2.id">
                                      {{ item2 }}
                                    </li>
                                  </ol>
                                </div>
                                <div v-if="item.type == 'table'" style="padding-bottom: 20px">
                                  <h6>
                                    {{ item.title.chapter }}
                                    {{ item.title.text }}
                                  </h6>
                                  <table class="table table-sm">
                                    <tbody>
                                      <tr v-for="item2 in item.list" :key="item2.id">
                                        <td>
                                          <p>
                                            {{ item2.split("=")[0] }}
                                          </p>
                                        </td>
                                        <td>
                                          {{ item2.split("=")[1] }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <h4 v-if="contentCategory == 'components'" style="padding-bottom: 20px">
                              {{ inspectionContent.components.title.text }}
                            </h4>
                            <div v-if="!isPVOrBat">
                              <table class="table table-sm" v-if="contentCategory == 'components'">
                                <thead>
                                  <tr>
                                    <th>Komponenten</th>
                                    <th>Hersteller</th>
                                    <th>Typ</th>
                                    <th>Seriennummer</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item in inspectionContent.components
                                    .list" :key="item.id">
                                    <td>{{ item.component }}</td>
                                    <td>{{ item.manufacturer }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.serialNumber }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <h4 v-if="contentCategory == 'machinedata'" style="padding-bottom: 20px">
                              {{ inspectionContent.machinedata.title.text }}
                            </h4>
                            <div v-if="!isPVOrBat">
                              <table class="table table-sm" v-if="contentCategory == 'machinedata'">
                                <thead>
                                  <tr>
                                    <th>Title</th>
                                    <th>Text</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="item in inspectionContent.machinedata
                                    .list" :key="item.id">
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.text }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div v-if="isPVOrBat && contentCategory == 'machinedata'
                            ">
                              <div v-if="inspectionContent.machinedata_pv.data
                                .identification
                              ">
                                <div style="margin-bottom: 30px">
                                  {{
                                    inspectionContent.machinedata_pv.data
                                      .identification_machine
                                  }}
                                </div>

                                <div style="margin-bottom: 30px">
                                  <h6>{{ $t("PV_IDENTIFICATION") }}</h6>
                                  <table class="table table-sm">
                                    <tbody>
                                      <tr>
                                        <td>{{ $t("PV_SOLAR_PARK") }}</td>
                                        <td>
                                          {{
                                            inspectionContent.machinedata_pv
                                              .data.identification.park
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{{ $t("LOCATION") }}</td>
                                        <td>
                                          {{
                                            inspectionContent.machinedata_pv
                                              .data.identification.lat
                                          }}
                                          N;
                                          {{
                                            inspectionContent.machinedata_pv
                                              .data.identification.lng
                                          }}
                                          E
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{{ $t("PV_CITY") }}</td>
                                        <td>
                                          {{
                                            inspectionContent.machinedata_pv
                                              .data.identification.city
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{{ $t("PV_COUNTRY") }}</td>
                                        <td>
                                          {{
                                            inspectionContent.machinedata_pv
                                              .data.identification.country
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{{ $t("PV_MACHINE_TYPE") }}</td>
                                        <td>
                                          {{
                                            $t(
                                              inspectionContent.machinedata_pv
                                                .data.identification.type
                                            )
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <h6>{{ $t("PV_MODULE") }}</h6>
                                <table style="margin-bottom: 10px" class="table table-sm" v-for="(item, index) in inspectionContent
                                  .machinedata_pv.data.pv_module_details" :key="index">
                                  <tbody>
                                    <tr>
                                      <td>
                                        {{ $t("MANUFACTURER") }} ({{
                                          index + 1
                                        }})
                                      </td>
                                      <td>
                                        {{ item.manufacturer }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ $t("TYPE") }} ({{ index + 1 }})
                                      </td>
                                      <td>
                                        {{ item.type }}; {{ item.number }} St.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ $t("NOMINAL_POWER") }} ({{
                                          index + 1
                                        }})
                                      </td>
                                      <td>{{ item.power }} Wp</td>
                                    </tr>
                                  </tbody>
                                </table>

                                <table style="margin-bottom: 20px" class="table table-sm">
                                  <tbody>
                                    <tr>
                                      <td>{{ $t("PV_NUMBER_TOTAL") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_module_total
                                        }}
                                        St.
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <h6>{{ $t("FURTHER_DETAILS") }}</h6>
                                <table style="margin-bottom: 10px" class="table table-sm">
                                  <tbody>
                                    <tr>
                                      <td>{{ $t("PV_GRID_CONNECTION") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_grid_connection
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{{ $t("PV_GRID_OPERATOR") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_grid_operator
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{{ $t("PV_INSTALLER") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_installer
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{{ $t("PV_OWNER") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_owner
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{{ $t("PV_COMMISSIONING_EEB") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_comission_eeb
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{{ $t("PV_COMMISSIONING") }}</td>
                                      <td>
                                        {{
                                          inspectionContent.machinedata_pv.data
                                            .pv_comission
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <h4 v-if="selected_item && contentCategory == 'findings'
                            ">
                              {{ selected_item.title.text }}

                              <span v-if="inspectionContent.processingState.map[
                                selected_item.uid
                              ]
                              ">({{
                                inspectionContent.processingState.map[
                                  selected_item.uid
                                ].istzustand
                              }}%)</span>
                            </h4>
                            <table class="table table-sm" v-if="contentCategory == 'findings'">
                              <thead>
                                <tr>
                                  <th style="width: 80px">Nr</th>
                                  <th style="width: 50px"></th>
                                  <th>Result</th>
                                </tr>
                              </thead>
                              <tbody>

                                <tr v-for="(item, index) in filteredFindings" :key="index">

                                  <td>
                                    <span v-if="!isProcessingControl && !item.hideRating">{{ item.pos }}
                                    </span>
                                    <span v-if="isProcessingControl && !item.hideRating">{{ item.pos_local }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="!item.hideRating" class="badge badge-primary" v-bind:style="stateColorStyle(
                                      item.last_state
                                        ? item.last_state
                                        : item.state
                                    )
                                      ">{{
                                        renameState(item.last_state
                                          ? item.last_state
                                          : item.state)
                                      }}</span>
                                  </td>

                                  <td>
                                    <div class="result-text" v-html="item.text" />
                                    <div @click="selectRemark(item)" v-if="item.remark_uid" class="remark-info">
                                      {{ item.remark_uid }}
                                    </div>


                                    <div v-if="item.refResults">
                                      <div v-for="itemRef in item.refResults" :key="itemRef.id">
                                        {{ itemRef.state }}:
                                        <span class="result-text" v-html="itemRef.text" />
                                      </div>
                                    </div>

                                    <div v-if="item.gps && item.gps.lat != ''">
                                      <a class="remark-info" :href="'https://www.google.com/maps/place/' +
                                        item.gps.lat +
                                        '+' +
                                        item.gps.lng
                                        " target="_blank">Google Maps</a>
                                    </div>
                                    <div v-if="item.attachments &&
                                      item.attachments.length > 0
                                    ">
                                      <div v-for="(itemPic, index) in item.attachments" :key="index" class="prev-img">
                                        <a :href="pictureUrlFull(item.id, itemPic)
                                          " target="_blank">
                                          <img :src="pictureUrl(item.id, itemPic)" />
                                        </a>
                                      </div>
                                    </div>
                                  </td>

                                </tr>

                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-sm-3" v-if="showPreview">
                          <div style="text-align: right;">
                            <a href="#" @click="showPreview = false">Close</a>
                          </div>

                          <iframe :src="pdfUrl" width="100%" class="iframe-height"></iframe>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->

    <ChapterImageModal :findingsForChapter="findingsForChapter" :findingsForChapterAll="findingsForChapterAll"
      :projectNumber="project_id" :inspectionEntry="inspectionEntry" :chapterId="selectedChapterId"
      :projectUid="projectUid" @dialogClosed="dialogClosed" v-if="showChapterImage"></ChapterImageModal>
    <ChapterSettingsModal :projectNumber="project_id" :findingsForChapterAll="findingsForChapterAll"
      :inspectionEntry="inspectionEntry" :list="chapterList" :chapterId="selectedChapterId" :projectUid="projectUid"
      @dialogClosed="dialogClosed" v-if="showChapterSettings"></ChapterSettingsModal>
    <InspectionSettings :projectNumber="project_id" :findingsForChapterAll="findingsForChapterAll"
      :inspectionEntry="inspectionEntry" :chapterId="selectedChapterId" :projectUid="projectUid"
      @dialogClosed="dialogClosed" v-if="showReportSettings"></InspectionSettings>
  </div>
</template>

<script>

import Header from "../../components/Header.vue";
import {
  getInspections,
  getInspection,
  generateReport,
  generateXLSX
} from "@/services/reporting-v3.service";
import ChapterSettingsModal from "../../components/modal/ChapterSettings.vue";
import ChapterImageModal from "../../components/modal/ChapterImageModal.vue";
import InspectionSettings from "../../components/modal/InspectionSettings.vue";
import { EventBus } from "@/event-bus.js";
//import { Multipane, MultipaneResizer } from 'vue-multipane';

export default {
  components: {
    Header,
    ChapterSettingsModal,
    ChapterImageModal,
    InspectionSettings,
    //Multipane,
    //MultipaneResizer
  },
  data() {
    return {
      contentCategory: "",
      lang: "de",
      inspectionContent: {},
      remarkList: [],
      isPVOrBat: false,
      inspectionEntry: null,
      all_findings: [],
      lastVersion: null,
      remarkStage: null,
      projectNumber: null,
      findingsChapters: [],
      findingsForChapter: [],
      findingsForChapterAll: [],
      showChapterSettings: false,
      detailsGeneral: {},
      showChapterImage: false,
      selectedChapterId: null,
      states: {},
      showReportSettings: false,
      projectUid: null,
      pdfLoading: false,
      chapterList: [],
      loading: false,
      reportName: "",
      inspect_chapter_uid: "",
      isProcessingControl: false,
      inspection_loading: false,
      selected_item: null,
      chapters_settings: {},
      versionList: [],
      showPreview: false,
      inspectionVersion: {},
      pdfUrl: null,// 'http://localhost:3202/pdfViewerInline/8p2_9909_Bericht_MA_100285_MM100_WKP-Plus_2023_E2.pdf?inspect_id=19911331&file=8p2_9909_Bericht_MA_100285_MM100_WKP-Plus_2023_E2.pdf',
    };
  },
  computed: {
    filteredFindings() {
      return this.findingsForChapter.filter((item) => !item.invisible);
    },
    inspections: function () {
      if (this.$store.state.rp_inspections) {
        let list = this.$store.state.rp_inspections;
        list.sort((a, b) => {
          if (a.machineName === b.machine.name) {
            return a.machine.siteNumber - b.machine.siteNumber;
          }
          return a.machine.name - b.machine.name;
        });

        return list;
      }

      return [];
    },
    report_role_permissions: function () {
      return this.$store.state.account_role.report == "write";
    },
    inspection_count: function () {
      return this.$store.state.rp_inspections.length;
    },
  },

  mounted() {
    this.project_id = this.$route.params.project_id;
    this.inspect_id = this.$route.params.inspect_id;
    this.lang = this.$route.params.lang;
    this.inspect_chapter_uid = this.$route.params.uid;

    EventBus.$off("refresh:results");
    EventBus.$on("refresh:results", this.refreshResults);


    document.addEventListener('keydown', this.handleKeydown);

    this.loading = true;
    getInspections(this.project_id, this.inspect_id).then((d) => {
      this.$store.state.rp_project = d.data.project;
      this.$store.state.rp_inspections = d.data.list;

      this.inspectionEntry = this.selectEntryById(this.inspect_id);

      this.inspectionVersion[this.inspectionEntry.uid] =
        this.inspectionEntry.rvm.list[this.inspectionEntry.rvm.list.length - 1];

      this.inspectionChanged();
      this.$store.state.rp_state_color = d.data.colors;
      this.loading = false;
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {

    handleKeydown(event) {
      // Überprüfen, ob CMD (für Mac) oder CTRL (für Windows/Linux) zusammen mit 'V' gedrückt wurde
      if (event.shiftKey && (event.metaKey || event.ctrlKey) && (event.key === 'V' || event.key === 'v')) {
        this.generatePdfReport(false)
        // Verhindern Sie das Standardverhalten, falls notwendig
        event.stopPropagation();
        event.preventDefault();
      }

      if (event.shiftKey && (event.metaKey || event.ctrlKey) && (event.key === 'B' || event.key === 'b')) {
        this.generatePdfReport(true)
        // Verhindern Sie das Standardverhalten, falls notwendig
        event.stopPropagation();
        event.preventDefault();
      }

      if (event.shiftKey && (event.metaKey || event.ctrlKey) && (event.key === 'S' || event.key === 's')) {
        EventBus.$emit("keydown", "save");
        event.preventDefault();
      }
    },

    refreshResults(d) {
      if (this.project_id == d.project_id && this.inspect_id == d.inspect_id) {
        console.log("neu laen");
        this.loadInspection();
      }
    },
    selectEntryById(inspect_id) {
      for (let i = 0; i < this.inspections.length; i++) {
        if (this.inspections[i].uid == inspect_id) {
          return this.inspections[i];
        }
      }
      return this.inspections[0];
    },
    changeVersion() {
      this.inspectionVersion[this.inspectionEntry.uid] = this.lastVersion;
      this.inspectionChanged();
    },
    selectRemark(item) {
      this.$store.socket.sendRSelectRemark(
        item.remark_uid,
        this.project_id,
        this.inspect_id
      );
    },
    stateColorStyle: function (state) {
      return {
        background: this.$store.state.rp_state_color[state],
        "border-color": this.$store.state.rp_state_color[state],
      };
    },
    renameState: function (state) {
      if (this.states[state]) {
        return this.states[state]
      }
      return state
    },
    dialogClosed: function (data) {

      if (data && data.cat == 'inspection' && data.saved) {
        // Refresh Inspections
        this.loadInspection()
      }
      this.showChapterSettings = false;
      this.showChapterImage = false;
      this.showReportSettings = false;
      this._updateChapterSettings();
    },
    pictureUrlFull: function (id, attachment) {
      let key = ""

      if (attachment.project_id) {
        key += attachment.project_id + "/"
      }
      else {
        key += this.project_id + "/"
      }

      if (attachment.inspect_id) {
        key += attachment.inspect_id + "/"
      }
      else {
        key += this.inspect_id + "/"
      }

      key += attachment.file;
      //console.log(attachment, this.$apiBase)
      return this.$apiBase + "/img-remark/" + "?key=" + key;
    },

    pictureUrl: function (id, attachment) {
      let key = ""

      if (attachment.project_id) {
        key += attachment.project_id + "/"
      }
      else {
        key += this.project_id + "/"
      }

      if (attachment.inspect_id) {
        key += attachment.inspect_id + "/"
      }
      else {
        key += this.inspect_id + "/"
      }

      key += attachment.file;
      //console.log(attachment, this.$apiBase)
      return this.$apiBase + "/img-url?key=" + key;
    },
    reportSettings: function (inspection_entry) {
      this.showReportSettings = true;
      console.log("inspection", inspection_entry.inspection);
    },
    chapterImage: function (id) {
      this.selectFindingsChapter(id);
      this.selectedChapterId = id;
      this.showChapterImage = true;
    },
    chapterSettings: function (item) {
      console.log(item);
      // this.selectFindingsChapter(id);
      this.selectedChapterId = item;
      this.chapterList = item.list;
      this.showChapterSettings = true;
    },
    generateXLSX: function () {
      //const ref = this;

      generateXLSX(
        this.inspectionEntry.project_id,
        this.inspectionEntry.inspect_id,
        this.lang,
        this.reportName,
        this.lastVersion,
        this.projectUid
      ).then((d) => {
        if (d.data) {
          console.log(d.data)
        }
      });

    },
    generatePdfReport: function (download) {
      const ref = this;

      this.pdfLoading = true;
      generateReport(
        this.inspectionEntry.project_id,
        this.inspectionEntry.inspect_id,
        this.lang,
        this.reportName,
        this.lastVersion,
        this.projectUid
      ).then((d) => {
        setTimeout(function () {
          ref.pdfLoading = false;

          if (d.data) {

            if (download) {
              window.open(
                ref.$apiBase +
                "/pdfViewer/" +
                d.data.file +
                "?inspect_id=" +
                ref.inspectionEntry.project_id +
                "&file=" +
                d.data.file
              );
            }
            else {

              ref.showPreview = true

              ref.pdfUrl = ref.$pdfBase +
                "/pdfViewerInline/" +
                d.data.file +
                "?inspect_id=" +
                ref.inspectionEntry.project_id +
                "&file=" +
                d.data.file + "&t=" + new Date().getTime()
            }

          }
        }, 500);
      });
    },

    selectChapter: function (item) {
      this.contentCategory = item;
      console.log(item);
    },

    selectFindingsChapter: function (item) {
      console.log("__selectFindingsChapter__", item);

      this.contentCategory = "findings";

      this.findingsForChapterAll = this.findAllFindingsInAllLevel(
        item.uid,
        item.level
      );

      this.selected_item = item;


      this.inspect_chapter_uid = item.uid;
      history.pushState(
        {},
        null,
        "/report-v3/" +
        this.lang +
        "/" +
        this.inspectionEntry.project_id +
        "/" +
        this.inspectionEntry.uid +
        "/" +
        item.uid
      );

      // console.log("____",   this.inspectionContent.processingState.map[item.uid])

      this.findingsForChapter = this.findingsForChapterAll; // item.list;

      for (let i = 0; i < this.findingsForChapter.length; i++) {
        if (
          this.findingsForChapter[i].refResults &&
          this.findingsForChapter[i].refResults.length > 0
        ) {
          this.findingsForChapter[i].attachments =
            this.findingsForChapter[i].refResults[
              this.findingsForChapter[i].refResults.length - 1
            ].attachments;
          this.findingsForChapter[i].pictures =
            this.findingsForChapter[i].refResults[
              this.findingsForChapter[i].refResults.length - 1
            ].pictures;
        }
      }

      console.log(
        "==this.findingsForChapterAll",
        this.findingsForChapterAll,
        "___",
        this.findingsForChapter
      );
      //this.allFindings(id, true);
    },
    selectInspectionRow(val) {
      this.showPreview = false
      this.inspectionEntry = val;
      this.inspectionChanged();
    },

    loadInspection: function () {
      this.inspection_loading = true;

      getInspection(
        this.inspectionEntry.project_id,
        this.inspectionEntry.uid,
        this.lang,
        this.inspectionVersion[this.inspectionEntry.uid],
        (this.$store.state.rp_project) ? this.$store.state.rp_project.uid : null
      ).then((d) => {
        this.findingsChapters = [];

        console.log("INSPECTION", d.data);

        //this.chapters_settings = d.data.chapters_settings

        this.$store.state.chapters_settings = d.data.chapters_settings;

        this.all_findings = d.data.content.v.findings.list;

        this.setFindingsChapters(
          this.all_findings,
          this.$store.state.chapters_settings
        );

        this.inspectionContent = d.data.content.v;
        this.inspection_base = d.data.inspection;

        this.detailsGeneral = d.data.detailsGeneral;

        this.$store.state.inspectionContent = this.inspectionContent

        //this.lastVersion = d.data.lastVersion;

        this.setMainChapters(d.data.content.v);

        this.reportName = d.data.reportName;
        this.projectUid = d.data.project_uid;

        this.inspection_loading = false;

        this.states = d.data.states

        if (
          d.data.inspection &&
          d.data.inspection.rvm &&
          d.data.inspection.rvm.list
        ) {
          this.versionList = d.data.inspection.rvm.list;
        }

        this.isPVOrBat = d.data.isBAT || d.data.isPV;
        //  this.inspection_base.project_cat == "PV" ||
        //  this.inspection_base.project_cat == "BAT";

        console.log(
          "isPVOrBat",
          this.isPVOrBat,
          d.data.isBAT,
          d.data.isPV,
          this.inspection_base.project_cat
        );

        this.isProcessingControl =
          this.inspection_base.processingControl &&
          this.inspection_base.processingControlExpanded;

        if (this.inspect_chapter_uid) {
          for (let k = 0; k < this.findingsChapters.length; k++) {
            if (this.findingsChapters[k].uid == this.inspect_chapter_uid) {
              console.log("ja ich bin", this.findingsChapters[k]);
              this.selectFindingsChapter(this.findingsChapters[k]);
              break;
            }
          }
          console.log(this.findingsChapters, "----", this.inspect_chapter_uid);
        } else {
          this.selectChapter("general");
        }
      });
    },
    inspectionChanged: function () {
      this.findingsForChapter = [];
      if (this.inspectionEntry) {

        if (!this.inspectionVersion[this.inspectionEntry.uid]) {
          this.inspectionVersion[this.inspectionEntry.uid] =
            this.inspectionEntry.rvm.list[
            this.inspectionEntry.rvm.list.length - 1
            ];
        }
        this.lastVersion = this.inspectionVersion[this.inspectionEntry.uid];

        this.inspect_id = this.inspectionEntry.uid;

        history.pushState(
          {},
          null,
          "/report-v3/" +
          this.lang +
          "/" +
          this.inspectionEntry.project_id +
          "/" +
          this.inspectionEntry.uid
        );

        this.loadInspection();
      }
    },

    findAllFindingsInAllLevel(uid, level) {
      let start = false;

      let int_list = [];

      console.log("findAllFindingsInAllLevel", uid, level, this.all_findings);

      for (let i = 0; i < this.all_findings.length; i++) {
        if (this.all_findings[i].id == uid) {
          start = true;
          for (let m = 0; m < this.all_findings[i].list.length; m++) {
            int_list.push(this.all_findings[i].list[m]);
          }
        } else if (start && this.all_findings[i].level > level) {
          for (let m = 0; m < this.all_findings[i].list.length; m++) {
            int_list.push(this.all_findings[i].list[m]);
          }
        } else {
          if (start) {
            break;
          }
        }
      }


      return int_list;
    },

    setFindingsChapters(list, chapter_settings) {
      for (let i = 0; i < list.length; i++) {
        let chapter_entry = {};

        for (let k = 0; k < chapter_settings.length; k++) {
          let chapterIdPrx =
            list[i].title.chapter && list[i].title.chapter.length > 0
              ? list[i].title.chapter + "-"
              : "";

          if (
            chapter_settings[k].chapterId ==
            chapterIdPrx + "" + list[i].level + "-" + list[i].title.text
          ) {
            chapter_entry = chapter_settings[k];
            break;
          }
        }

        //console.log(list[i]);
        this.findingsChapters.push({
          level: list[i].level,
          uid: list[i].id,
          title: list[i].title,
          chapter: list[i].title.chapter,
          list: list[i].list,
          id: list[i].level + "-" + list[i].title.text,
          count: list[i].list ? list[i].list.length : 0,
          //chapter_entry: chapter_entry,
          chapterimage: chapter_entry.chapterimage,
          chapterfile: chapter_entry.chapterfile,
          chapterincludes: chapter_entry.chapterincludes,
          chapterV: chapter_entry.chapterV,
          newpagepictures: chapter_entry.newpagepictures,
          newpage: chapter_entry.newpage,
        });
      }
    },

    setMainChapters(obj) {
      console.log("setMainChapters", obj);

      this.$store.state.main_chapters = [];

      this.$store.state.main_chapters.push({
        id: "inspectionDetails",
        chapter: obj.inspectionDetails.title.chapter,
        title: obj.inspectionDetails.title.text,
      });

      this.$store.state.main_chapters.push({
        id: "general",
        chapter: obj.general.title.chapter,
        title: obj.general.title.text,
      });

      if (obj.general.list) {
        for (let m = 0; m < obj.general.list.length; m++) {
          this.$store.state.main_chapters.push({
            id: "general" + obj.general.list[m].title.chapter,
            chapter: obj.general.list[m].title.chapter,
            title: obj.general.list[m].title.text,
          });
        }
      }

      this.$store.state.main_chapters.push({
        id: "machinedata",
        chapter: obj.machinedata.title.chapter,
        title: obj.machinedata.title.text,
      });

      if (this.detailsGeneral && this.detailsGeneral.general && !this.detailsGeneral.general.processing_control_expanded) {
        this.$store.state.main_chapters.push({
          id: "components",
          chapter: obj.components.title ? obj.components.title.chapter : "",
          title: obj.components.title ? obj.components.title.text : "",
        });
      }


      this.$store.state.main_chapters.push({
        id: "findings",
        chapter: obj.findings.title.chapter,
        title: obj.findings.title.text,
      });

      for (let i = 0; i < this.findingsChapters.length; i++) {
        if (this.findingsChapters[i].level < 3) {
          this.$store.state.main_chapters.push({
            id: "findings" + i,
            chapter: this.findingsChapters[i].title.chapter,
            title: this.findingsChapters[i].title.text,
          });
        }
      }

      this.$store.state.main_chapters.push({
        id: "conclusion",
        chapter: obj.conclusion.title.chapter,
        title: obj.conclusion.title.text,
      });

      if (obj.conclusion.chapters) {
        for (let m = 0; m < obj.conclusion.chapters.length; m++) {
          this.$store.state.main_chapters.push({
            id: "conclusion" + obj.conclusion.chapters[m].title.chapter,
            chapter: obj.conclusion.chapters[m].title.chapter,
            title: obj.conclusion.chapters[m].title.text,
          });
        }
      }

      this.$store.state.main_chapters.push({
        id: "disclaimer",
        chapter: obj.disclaimer.title.chapter,
        title: obj.disclaimer.title.text,
      });


      this.$store.state.main_chapter = this.shiftChapters(this.$store.state.main_chapters)



    },
    shiftChapters: function (chapters) {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let currentCharIndex = 0;
      let lastMainChapter = '';

      for (let k = 0; k < chapters.length; k++) {
        let item = chapters[k];
        const parts = item.chapter.split('.');

        if (parts.length == 1) {
          lastMainChapter = alphabet[currentCharIndex++];
          parts[0] = lastMainChapter;
        }

        else {
          parts[0] = lastMainChapter;
        }

        if (currentCharIndex >= alphabet.length) {
          currentCharIndex = 0;
        }
        // Baut das neue Kapitel zusammen
        const newChapter = parts.join('.');

        item.chapter = newChapter


      }

      return chapters

    },


    allFindings: function (id, allLevel) {
      let found = false;
      let level = 0;
      this.findingsForChapter = [];

      for (let k = 0; k < this.inspectionEntry.findings.list.length; k++) {
        if (found) {
          if (this.inspectionEntry.findings.list[k].level > level) {
            for (
              let m = 0;
              m < this.inspectionEntry.findings.list[k].list.length;
              m++
            ) {
              this.findingsForChapter.push(
                this.inspectionEntry.findings.list[k].list[m]
              );
            }
          } else {
            break;
          }
        }

        if (this.inspectionEntry.findings.list[k].id == id) {
          console.log(this.inspectionEntry.findings.list[k]);
          level = this.inspectionEntry.findings.list[k].level;
          found = true;

          for (
            let m = 0;
            m < this.inspectionEntry.findings.list[k].list.length;
            m++
          ) {
            this.findingsForChapter.push(
              this.inspectionEntry.findings.list[k].list[m]
            );
          }
        }

        if (!allLevel) {
          break;
        }
      }
    },

    _findings: function (list) {
      if (list && list.length > 0) {
        for (let k = 0; k < list.length; k++) {
          if (list[k].level) {
            //console.log(list[k].level, list[k], list[k].title.text, list[k].id);
            list[k].uid = list[k].id + "-" + Math.random();
            this.findingsChapters.push(list[k]);
          }
        }
      }
      this._updateChapterSettings();
    },
    _updateChapterSettings: function () {
      for (let n = 0; n < this.findingsChapters.length; n++) {
        this.findingsChapters[n].chapterimage = false;
        for (
          let m = 0;
          m < this.$store.state.rp_chapters_settings.length;
          m++
        ) {
          if (
            this.$store.state.rp_chapters_settings[m].chapterId ==
            this.findingsChapters[n].id
          ) {
            this.findingsChapters[n].chapterimage = this.$store.state
              .rp_chapters_settings[m].chapterimage
              ? this.$store.state.rp_chapters_settings[m].chapterimage
              : false;
            break;
          }
        }
      }

      //let cache_findings = JSON.parse(JSON.stringify(this.findingsChapters));
      this.findingsChapters = [];

      this.setFindingsChapters(
        this.all_findings,
        this.$store.state.chapters_settings
      );
    },
    selectInspection: function () {
      this.findingsChapters = [];
      this._findings(this.inspectionEntry.findings.list);
    },
    downloadURI: function (uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    saveCanvas: function () {
      var dataURL = this.remarkStage.toDataURL({
        pixelRatio: 1,
      });

      this.downloadURI(dataURL, "stage.png");
    },
    generateMarker: function (id, nr, posX, posY, textPosX, textPosY, color) {
      const ref = this;

      var group = new this.$konva.Group({
        draggable: true,
        x: posX,
        y: posY,
        id: id,
      });
      var node = new this.$konva.Circle({
        stroke: color,
        strokeWidth: 2,
        radius: 10,
        x: 10,
        y: 10,
      });
      group.on("dragend", function () {
        ref.updateRemarkPos(
          group.id(),
          group.x(),
          group.y(),
          simpleText.x(),
          simpleText.y()
        );

        //var dataURL = stage.toDataURL({ pixelRatio: 1 });
        //console.log(dataURL)
      });
      group.add(node);
      var simpleText = new this.$konva.Text({
        text: nr,
        //align: 'center',
        x: textPosX,
        y: textPosY,
        width: 40,
        fontSize: 18,
        fontFamily: "Arial",
        fill: color,
      });
      group.add(simpleText);

      group.on("mouseenter", function () {
        ref.remarkStage.container().style.cursor = "pointer";
      });
      group.on("mouseleave", function () {
        ref.remarkStage.container().style.cursor = "default";
      });
      simpleText.on("pointerdblclick", function () {
        //group.draggable = false
        simpleText.startDrag();
        //simpleText.draggable = true
      });
      return group;
    },
    updateRemarkPos: function (id, x, y, tX, tY) {
      for (let k = 0; k < this.remarkList.length; k++) {
        if (this.remarkList[k].id == id) {
          this.remarkList[k].x = x;
          this.remarkList[k].y = y;
          this.remarkList[k].tX = tX;
          this.remarkList[k].tY = tY;
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.chapterTable {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}

.chapterRow {
  cursor: pointer;
  padding: 4px 10px 4px 0;
  position: relative;
  display: table;
  width: 100%;
}

.chapterRow>.chapterRow-col1 {
  display: table-cell;
  vertical-align: top;
  width: 20px;
}

.chapterRow>.chapterRow-col2 {
  display: table-cell;
  vertical-align: top;
  font-size: 15px;
}

.chapterRow>.chapterRow-col3 {
  display: table-cell;
  vertical-align: top;
  width: 20px;
}

.chapterRow>.chapterRow-col2:hover {
  color: #798bff;
}

.chaper-icon>.material-icons {
  font-size: 18px;
  color: #8094ae;
}

.statev {
  border-color: #3bc600;
  background-color: #3bc600;
}

.wide-large {
  max-width: 100% !important;
}

.prev-img {
  float: left;
  padding-right: 10px;
  padding-top: 10px;
}

.prev-img img {
  width: 110px;
  padding-bottom: 5px;
}

.result-text {
  font-size: 16px;
}

.inspection-row {
  cursor: pointer;
}

.container-col1 {
  height: calc(100vh - 150px);
  /* Maximale Höhe des Browserfensters */
  overflow-y: auto;
  /* Scrollbalken anzeigen, wenn der Inhalt zu lang ist */
}

.remark-info {
  font-size: 12px;
  color: #999999;
  cursor: pointer;
}

.chapter-headline {
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
}

h6:hover {
  color: #798bff;
}

.insection_selected {
  background-color: #8094ae;
  color: #ffffff;
}

h4,
.h4 {
  font-size: 1.3rem;
}

.iframe-height {
  min-height: 700px;
  height: 80vh;
}

.multipane.foo.layout-v .multipane-resizer {
  margin: 0;
  left: 0;
  /* reset default styling */
  width: 15px;
  background: blue;
}

.multipane.foo.layout-h .multipane-resizer {
  margin: 0;
  top: 0;
  /* reset default styling */
  height: 15px;
  background: blue;
}
</style>