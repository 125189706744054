<template>
  <div>
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap">
          <Header v-show="!loading_page" />
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container wide-xl">
              <div class="nk-content-inner">
                <Navigation v-show="!loading_page" />
                <!--
                <div class="nk-content-body" v-show="loading_page">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                -->
                <div class="nk-content-body" v-show="!loading_page">
                  <div class="nk-content-wrap">
                    <div class="nk-block-head nk-block-head-lg">
                      <div class="nk-block-head-sub">
                        <span>8.2 Inspect</span>
                      </div>
                      <div class="nk-block-head-content">
                        <h2 class="nk-block-title fw-normal">
                          User List
                        </h2>
                      </div>
                    </div>

                    <div class="nk-block">
                      <div class="card card-bordered card-stretch">
                        <div class="card-inner-group">
                          <div class="card-inner">
                            <div class="card-title-group">
                              {{ bookingList.length }} Entries
                              <div class="card-tools mr-n1">
                                <ul class="btn-toolbar">

                                  <!-- li -->
                                  <li class="btn-toolbar-sep"></li>
                                  <!-- li -->

                                  <li>
                                    <div class="dropdown">
                                      <a href="#" class="
                                          btn btn-trigger btn-icon
                                          dropdown-toggle
                                        " data-toggle="dropdown">
                                        <em class="icon ni ni-setting"></em>
                                      </a>
                                      <div class="
                                          dropdown-menu
                                          dropdown-menu-right
                                          dropdown-menu-xs
                                        ">
                                        <ul class="link-check">
                                          <li>
                                            <span style="cursor: pointer" @click="exportCSV()">Export CSV</span>
                                          </li>

                                        </ul>
                                      </div>
                                    </div>
                                  </li>

                                  <!-- li -->
                                </ul>
                                <!-- .btn-toolbar -->
                              </div>
                              <!-- card-tools -->
                              <div class="card-search search-wrap" data-search="search">
                                <div class="search-content">
                                  <a href="#" class="
                                      search-back
                                      btn btn-icon
                                      toggle-search
                                    " data-target="search"><em class="icon ni ni-arrow-left"></em></a>
                                  <input type="text" class="
                                      form-control form-control-sm
                                      border-transparent
                                      form-focus-none
                                    " placeholder="Quick search by order id" />
                                  <button class="search-submit btn btn-icon">
                                    <em class="icon ni ni-search"></em>
                                  </button>
                                </div>
                              </div>
                              <!-- card-search -->
                            </div>
                            <!-- .card-title-group -->
                          </div>

                          <div class="form-group">
                            <input type="text" id="search" class="form-control" v-model="searchQuery"
                              placeholder="Search..." />
                          </div>

                          <!-- .card-inner -->
                          <div class="card-inner p-0">
                            <table class="table table-tranx">
                              <thead>
                                <tr class="tb-tnx-head">
                                  <!--
                                  <th class="tb-tnx-id" style="width: 50px">
                                    <span class="">#</span>
                                  </th>
                                  -->
                                  <th style="width: 100px;cursor:pointer;" @click="sortBy('created_at')">
                                    <span class="tb-tnx-total">Date</span>
                                  </th>
                                  <th style="cursor:pointer;" @click="sortBy('company')">Company</th>
                                  <th style="cursor:pointer;" @click="sortBy('username')">User</th>
                                  <th style="cursor:pointer;" @click="sortBy('login_system')">Login</th>
                                  <th style="cursor:pointer;" @click="sortBy('licence')">Licence</th>
                                  <th style="cursor:pointer;" @click="sortBy('licence_paid')">Paid</th>
                                </tr>
                                <!-- tb-tnx-item -->
                              </thead>
                              <tbody>
                                <tr v-for="(item) in filteredBookingList" :key="item._id" class="table-row"
                                  @click="selectUser(item)">
                                  <td class="tb-tnx-info">
                                    <span class="date">{{ item.created_at | moment("DD.MM.YY")
                                    }}</span>
                                  </td>
                                  <td class="tb-tnx-info">
                                    {{ item.company }}
                                  </td>
                                  <td class="tb-tnx-info">{{ item.username }}<br />
                                    {{ item.name }}</td>
                                  <td class="tb-tnx-info">{{ item.login_system }}</td>
                                  <td class="tb-tnx-info">
                                    <em v-if="item.licence" class="ni ni-check " style="font-size:24px;"></em>
                                  </td>
                                  <td class="tb-tnx-info">
                                    <em v-if="item.licence_paid" class="ni ni-coins" style="font-size:24px;"></em>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <!-- .card-inner -->

                          <!-- .card-inner -->
                        </div>
                        <!-- .card-inner-group -->
                      </div>
                      <!-- .card -->
                    </div>

                  </div>

                  <div class=" modal fade" id="modalDefault">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">User</h5>
                        </div>
                        <div class="modal-body">

                          <form action="#" class="form-validate is-alter" novalidate="novalidate">
                            <div class="form-group">
                              <div class="row gy-4">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Name</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" readonly="" v-model="userEntry.name" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Username</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" readonly=""
                                        v-model="userEntry.username" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Company</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" readonly="" v-model="userEntry.company" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row gy-4">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Login System</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" readonly=""
                                        v-model="userEntry.login_system" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Created</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" readonly=""
                                        v-model="userEntry.created_at" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Updated</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" readonly=""
                                        v-model="userEntry.updated_at" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <h4 class="mt-5">Licence Info</h4>
                            <div class="form-group">
                              <div class="row gy-4">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01"></label>
                                    <div class="form-control-wrap">
                                      <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck5"
                                          v-model="userEntry.licence" />
                                        <label class="custom-control-label" for="customCheck5">Valid
                                          licence</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Date</label>
                                    <div class="form-control-wrap">
                                      <input type="date" class="form-control " v-model="userEntry.licence_date">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Invoice</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="userEntry.licence_invoice" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="row gy-4">
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01"></label>
                                    <div class="form-control-wrap">
                                      <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck8"
                                          v-model="userEntry.licence_paid" />
                                        <label class="custom-control-label" for="customCheck8">Licence
                                          paid</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Info</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="userEntry.licence_info" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Notes</label>
                                    <div class="form-control-wrap">
                                      <textarea class="form-control no-resize" v-model="userEntry.licence_notes"
                                        id="default-textarea">Notes..</textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div class="form-group">
                              <button type="button" v-if="canSave" class="btn btn-lg btn-primary" @click="saveUser()">
                                Save user
                              </button>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer bg-light">

                        </div>
                      </div>
                    </div>
                  </div>

                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

import { getUsers, updateUser } from "@/services/account.service";

//import { EventBus } from '@/event-bus.js';

export default {
  name: "Home",
  data() {
    return {
      fullLoader: null,
      projectEntry: {},
      mapSort: {},
      searchkey: "",
      db_result: [],
      canSave: true,
      projectLoading: false,
      iec_daaks_value: "",
      settingsSaved: false,
      bookingDate: null,
      bookingList: [],
      userEntry: [],
      searchQuery: "",
    };
  },
  components: {
    Header,
    Navigation,
    Footer
  },

  computed: {
    filteredBookingList() {
      if (!this.searchQuery) {
        return this.bookingList;
      }
      const query = this.searchQuery.toLowerCase();
      return this.bookingList.filter((item) => {
        const company = item.company ? item.company.toLowerCase() : "";
        const name = item.name ? item.name.toLowerCase() : "";
        return company.includes(query) || name.includes(query);
      });
    },
    account_role_permissions: function () {
      return this.$store.state.account_role.permissions == "write";
    },
    users_role_permissions: function () {
      return this.$store.state.account_role.users == "write";
    },
    companyName: function () {
      return this.$store.state.company;
    },
    loginName: function () {
      return this.$store.state.name;
    },

    loading_page: function () {
      return (
        this.$store.state.loading_page &&
        this.$store.state.loading_page == "setup"
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (!this.users_role_permissions) {
      this.$store.dispatch("api_error", {});
    }

    this.actionGetUsers();
  },
  created() { },
  watch: {},
  methods: {

    saveUser: function () {
      this.showLoader();

      console.log(this.userEntry);
      $("#modalDefault").modal("hide");

      updateUser(this.userEntry).then((d) => {
        console.log(d);

        this.hideLoder();
      });

      /*
      getUsers(false).then((d) => {
        console.log(d);

        this.bookingList = d.data.list;

        this.hideLoder();
      });
      */
    },
    selectUser: function (data) {
      console.log(data);
      this.userEntry = data;
      $("#modalDefault").modal("show");
    },
    sortByKey(key, idx) {
      return (a, b) => {
        // Besondere Behandlung für null, undefined oder gemischte Typen
        if (a[key] === null || a[key] === undefined) return idx === -1 ? -1 : 1;
        if (b[key] === null || b[key] === undefined) return idx === -1 ? 1 : -1;

        // Bei booleschen Werten konvertieren wir explizit zu 0 und 1
        if (typeof a[key] === 'boolean' && typeof b[key] === 'boolean') {
          return idx === -1
            ? (a[key] === b[key] ? 0 : (a[key] ? 1 : -1))
            : (a[key] === b[key] ? 0 : (a[key] ? -1 : 1));
        }

        // Standardvergleich für andere Typen
        return idx === -1
          ? (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0)
          : (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      };
    },
    sortBy: function (val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.bookingList = this.bookingList.sort(
        this.sortByKey(val, this.mapSort[val])
      );

    },
    exportCSV: function () {

      this.showLoader();
      getUsers(true).then((resp) => {
        console.log(resp);
        this.bookingList = resp.data.list;

        if (resp.data.csv) {
          var csvData = new Blob(["\uFEFF" + resp.data.csv], {
            type: "text/csv;charset=windows-1252",
          });
          var csvURL = null;
          if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, "download.csv");
          } else {
            csvURL = window.URL.createObjectURL(csvData);
          }
          var tempLink = document.createElement("a");
          tempLink.href = csvURL;
          tempLink.setAttribute(
            "download",
            "inspect3_userlist.csv"
          );
          tempLink.click();
        }

        this.hideLoder();
      });

    },
    actionGetUsers: function () {

      this.showLoader();
      getUsers(false).then((d) => {
        console.log(d);

        this.bookingList = d.data.list;

        this.hideLoder();
      });

    },
    hideLoder: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },

    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style scoped>
.revo-grid {
  height: 50%;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f5f5f5;
}
</style>
